.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 30px;
  background-color: white;
  color: #333;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #39b54a59;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  display: inline-block;
  margin-left: 2.5rem;
}

.nav ul li a {
  color: #333;
  text-decoration: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn {
  background-color: transparent;
  color: #333;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.dropbtn:hover {
  opacity: 0.8;
}

/* .footerMain {
  content: "";
  background-image: url(../Images/Rectangle\ 7.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
} */

.container {
  display: flex;
  flex-direction: column;
}

.educampus-container {
  position: relative;
  display: inline-block;
}

.educampus-container:after {
  content: "";
  background-image: url("../Images/Frame\ 198\ \(1\).png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  margin-left: -152px;
  margin-bottom: -36px;
}

.educampus-container1 {
  position: relative;
  display: inline-block;
}

.courseCard {
  height: 100%;
  border-radius: 50px;
}

.CourseCard:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

.detailPageImage1 {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 80%;
  color: white;
}

.terms-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  font-family: Arial, sans-serif;
}

.terms-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.terms-container section {
  margin-bottom: 20px;
}

.terms-container h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.terms-container p {
  font-size: 1em;
  line-height: 1.6;
}

.popup {
  z-index: 9999999999999;
  position: absolute;
  background-color: gray;
  color: white;
  padding: 5px;
  top: 0%;
  left: 100%;
  transform: translate(0%, -50%);
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.popup ul {
  margin: 0;
  padding: 0;
  list-style-type: disc;
}

.popup ul li {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}
.footerMain {
  background-color: #222;
  color: white;
}

.footerContent {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.footerSection {
  padding: 1rem;
}

.footerSection h4 {
  margin-bottom: -1px;
}

.footerSection p,
.footerSection li {
  margin: 10px 0;
  cursor: pointer;
}

.footerSection ul {
  list-style-type: none;
  padding: 0;
}

.footerContact {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.footerContact p {
  margin: 0;
}

.footerSocial {
  display: flex;
  justify-content: center; /* Center icons on small screens */
}

.footerSocial img {
  margin-right: 10px;
}
.footerLogo {
  height: 500px;
  width: 50px;
}
.footerLogo img {
  height: 30px;
  width: 30px;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.overlay-content1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}

.custom-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom-scroll::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.logout-text {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.dropbtn:hover + .logout-text {
  visibility: visible;
  opacity: 1;
}

.read-more-button {
  background: none;
  border: none;
  color: #39b54a;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin-left: 5px;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .footerContent {
    flex-direction: row;
    justify-content: space-around;
  }
  .footerSection {
    flex: 1;
    padding: 0 1rem;
  }
}

@media (max-width: 767px) {
  .footerSection {
    text-align: center;
    padding: 1rem 0;
  }
  .footerContact {
    justify-content: center;
  }
  .footerLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .edulogo {
    width: 300px;
  }
  
}

@media only screen and (max-width: 300px) {
  .MainLogo {
    width: 150px;
    height: 100%;
    cursor: pointer;
  }
  
  .header-right {
    display: none;
  }
  .menuIcon {
    display: block;
  }
  .content1 {
    margin-bottom: 1rem;
  }
  .courseImage {
    height: 200px;
  }
  .content2 {
    display: none;
  }
  .content2JoinUs {
    padding: 0 1rem 1rem 1rem;
    margin-top: -1rem;
  }
  .mainTitle {
    font-size: 24px;
  }

  .educampus-container:after {
    content: "";
    background-image: url("../Images/Frame\ 198\ \(1\).png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 120px;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    margin-left: -122px;
    margin-bottom: -32px;
  }
  .FooterLogo {
    height: 50px;
    width: 230px;
  }
  .MobileViewFilter {
    display: none;
  }
  .MobileViewCategories {
    display: none;
  }
}

@media only screen and (min-width: 300px) {
  .MainLogo {
    width: 150px;
    height: 100%;
    cursor: pointer;
  }
  .header-right {
    display: none;
  }
  .menuIcon {
    display: block;
  }
  .content1 {
    margin-bottom: 1rem;
  }
  .courseImage {
    height: 200px;
  }
  .content2 {
    display: none;
  }
  .content2JoinUs {
    padding: 0 1rem 1rem 1rem;
    margin-top: -1rem;
  }
  .mainTitle {
    font-size: 24px;
  }

  .educampus-container:after {
    content: "";
    background-image: url("../Images/Frame\ 198\ \(1\).png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 120px;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    margin-left: -122px;
    margin-bottom: -32px;
  }
  .FooterLogo {
    height: 50px;
    width: 230px;
  }
  .MobileViewFilter {
    display: flex;
  }
  .MobileViewCategories {
    display: none;
  }

}

@media only screen and (min-width: 350px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (min-width: 600px) {
  .MainLogo {
    width: 250px;
    height: 100%;
    cursor: pointer;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-content2{
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .MobileViewFilter {
    display: none;
  }
  .MobileViewCategories {
    display: block;
  }
  .overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: block;
  }
  .courseImage {
    height: 400px;
  }
  .content2 {
    display: block;
  }
  .mainTitle {
    font-size: 32px;
  }
  .educampus-container:after {
    content: "";
    background-image: url("../Images/Frame\ 198\ \(1\).png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    margin-left: -152px;
    margin-bottom: -36px;
  }
  .FooterLogo {
    height: 50px;
    width: 270px;
  }
}

@media only screen and (min-width: 1200px) {
  .header-right {
    display: block;
  }
  .menuIcon {
    display: none;
  }
  .content1 {
    padding-left: 2rem;
    padding-right: 4rem;
  }
}
.testimonial-container {
  background-color: #f2f6ff;
}

.title {
  text-align: center;
  padding-top: 10px;
}

.testimonial-content {
  padding: 25px 50px 25px 50px; /* Adjusted for smaller screens */
}

/* .testimonial {
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  margin: 20px auto;
  text-align: left;
  position: relative;
} */

.testimonial {
  border: 1px solid #e0e0e0;
  border-top: 4px solid #39b54a;
  padding: 20px;
  padding-top: 15px;
  border-radius: 10px;
  background-color: white;
  margin: 20px auto;
  text-align: left;
  position: relative;
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.initial-circle {
  background-color: #39b54a;
  color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-right: 10px;
  overflow: hidden; /* Ensure content is clipped to the container's bounds */
}

.initial-circle img.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
}

.name-rating {
  display: flex;
  flex-direction: column;
}

.name {
  margin: 0;
  font-size: 1.5em;
}

.rating {
  display: flex;
}

.star {
  color: #ffc107;
}

.text {
  font-size: 1.1em;
  color: #555;
  padding-left: 15px;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.nav-button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #007bff;
}

.nav-button:disabled {
  opacity: 0.5; /* Reduced opacity for disabled state */
  cursor: default; /* Prevent cursor interaction */
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.dot {
  font-size: 24px;
  cursor: pointer;
  margin: 0 5px;
  color: gray;
}

.dot.active {
  color: black;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .testimonial-content {
    padding: 25px 20px 25px 20px;
  }

  .testimonial-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .initial-circle {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .name {
    font-size: 1.2em;
  }

  .text {
    font-size: 1em;
    padding-left: 15px;
  }

  .nav-button {
    font-size: 1.2em;
  }
  .mainContent{
    display: none;
  }
  .search{
    display: none; 
  }
  .MobileViewFilter {
    display: none;
  }
  
}

/* HomeAboutus.css */
.container {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  margin: 0 auto;
}

.box {
  background-color: white;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  transition:0.3s;
}

.box1,.box3{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.backgroundimg{
  background-image: url(../Images/background-aboutus.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;
}
.numberbox-img img{
  width: 100%;
}
.numberbox-img{
  width: 30%;
}
.numberCenterbox-img{
  /* width: 368px; */
  display: flex;
  justify-content: center;
}
.numberCenterbox-img img {
  width: 50%; /* Adjust image size for mobile */
}
.box:hover{
  transform: translateY(-10px);
}
/* TrainingCertification*/
.maequeepartnerimg {
  width: 200px;
  margin-right: 40px;
}
.nav .active {
  border-bottom: 2px solid #39b54a;
}